:root {
    --accent-color: #45C1B8;
}
.neo-cal-placeholder {
    position: relative;
    width: 0;
    height: 0;

}
.neo-date-picker {
    position: absolute;
    width: fit-content;
    padding: 1em;
    background-color: white;
    display: flex;
    gap: 1em;
    box-shadow: 0px 3px 6px #6664;
    z-index: 1;
}
.neo-date-picker-input.abbr {
    width: 11em;
}
.neo-date-picker-input{
    width: 14em;
    border: 1px solid #6662;
    border-bottom-color: #6664;
    border-radius: 0.2em;
    padding: 0.3em 0.5em;
}
.neo-date-picker-input:focus {
    outline: none;
    box-shadow: 0px 0px 0px 3px var(--accent-color) inset;
}
.neo-date-picker-input-wrapper {
    position: relative;
    width: fit-content;
}
.neo-date-picker-input-wrapper svg {
    position: absolute;
    right: 0;
    width: 2em;
    height: 100%;
    padding: 0.5em;
    color: #6668;
}
.neo-clock {
    display: flex;
    flex-wrap: nowrap;
    grid-area: time;
    gap: 0.3em;
}
.neo-clock > .neo-scratcher, .neo-clock > .neo-time-wrapper {
    height: 100%;
    overflow-y: auto;
    scrollbar-gutter: stable;
}
.neo-time{
    display: grid;
    grid-template-rows: [start] 75% [end];
    grid-template-areas:
        "time"
        "button";
}
.neo-date-picker-buttons {
    grid-area: button;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
}
.neo-time .neo-set-now, .neo-time .neo-done-date-picking {
    width: 100%;
    border: none;
    border-radius: 0.2em;
    background-color: var(--accent-color);
    color: white;
}
.neo-time > .neo-set-now:focus{
    outline: none;
}
.neo-calendar {
    position: relative;
}
.neo-calendar > .neo-calendar-controller {
    position: relative;
    top: 0;
    display: flex;
    gap: 1em;
    align-items: stretch;
    justify-content: space-between;
}
.neo-calendar th, .neo-calendar td {
    width: 3em;
    text-align: right;
    padding: 0.5em;
    user-select: none;
}
.neo-calendar td.today, .neo-calendar td.picked {
    border-radius: 0.3em;
}
.neo-calendar td.today {
    border-radius: 0.3em;
    border: 1px solid var(--accent-color)
}
.neo-calendar td.picked {
    color: white;
    background-color: var(--accent-color);
}
.neo-calendar th {
    cursor: default;
}
.neo-calendar td {
    cursor: pointer;
}
.neo-calendar th:first-child, .neo-calendar td:first-child{
    color: red;
}
.neo-calendar td.other-month:first-child{
    color: #f006;
}
.neo-calendar td.other-month {
    color: #6666;
}