:root {
    --accent-color: #45C1B8;
    --indicator-color: #b7e8e5;
}
.neo-picker-cancel {
    position: fixed;
    inset: 0;
    background-color: #0001;
    opacity: 0;
    pointer-events: none;
}
.neo-picker {
    position: relative;
    top: 0;
    height: 2em;
    width: fit-content;
    box-sizing: content-box;
    display: flex;
    z-index: 1;
}
.neo-picker.bare {
    display: block;
    height: unset;
}
.neo-picker.bare .neo-picker-item-wrapper{
    height: 2em;
}
.neo-picker.bare .neo-picker-list-container{
    max-height: 8em;
}
.neo-picker.bare .neo-picker-item-position{
    display: flex;
    flex-direction: column-reverse;
}
button.neo-bare-stepper-up, button.neo-bare-stepper-back {
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    height: 2em;
    border: 1px solid var(--accent-color);
    color: var(--accent-color);
    background-color: white;
}
button.neo-bare-stepper-up:hover, button.neo-bare-stepper-back:hover {
    background-color: var(--accent-color);
    color: white;
}
button.neo-bare-stepper-up {
    border-radius: 0.3em 0.3em 0 0;
}
button.neo-bare-stepper-back {
    border-radius: 0 0 0.3em 0.3em;
}
.neo-picker-list-container::-webkit-scrollbar{
    display: none;
    width: 0;
}
.neo-picker-list-container{
    overflow: auto;
}
.neo-picker.right .neo-picker-item{
    justify-content: right;
}
.neo-picker.bare .neo-picker-item-wrapper{
    border-radius: 0;
}
.neo-picker .neo-picker-item-wrapper {
    position: relative;
    top: 0;
    overflow: hidden;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #6662;
    border-bottom: 1px solid #6664;
    border-radius: 0.2em 0 0 0.2em;
    background-color: #fff;
    transition: 0.2s;
    z-index: 1;
}
.neo-picker[data-openned="true"] .neo-picker-item-wrapper > .neo-picker-item-position > .neo-picker-item:hover {
    background-color: var(--accent-color);
    color: white;
}
.neo-picker[data-openned="true"] .neo-picker-item-wrapper > .neo-picker-item-position > .neo-picker-item.picked{
    background-color: var(--indicator-color);
    color: black;
}
.neo-picker:not([data-openned="true"]) .neo-picker-item-wrapper > .neo-picker-item-position > .neo-picker-item:hover {
    background-color: #fff;
}
.neo-picker-item-position {
    position: relative;
    top: 0;
    transition: 0.2s;
}
.neo-picker-item {
    height: 2em;
    padding: 0 0.5em 0 1em;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}