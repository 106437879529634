.map-entry {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1em;
}
.map-marker {
  position: relative;
}
.map-marker > svg {
  color: #ea4336;
  width: 2em;
  height: 100%;
}
.map-marker::after {
  content: attr(data-label);
  position: absolute;
  inset: 0;
  text-align: center;
  font-weight: 700;
}
