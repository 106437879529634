.partition{
    position: fixed;
    inset: 0;
    overflow: auto;
    pointer-events: none;
    z-index: 1;
}
.partition::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: #222a;
    opacity: 0;
    backdrop-filter: blur(20px);
    transition: 0.2s ease-out;
}
.partition.modal-active {
    pointer-events: all;
}
.partition.modal-active::before {
    opacity: 1;
}
.sheet-modal {
    padding: 1em;
    transition: 0.2s ease-in-out;
}
.sheet-modal > h2 {
    text-align: center;
}
.sheet-modal > .signature-area {
    position: relative;
    margin: auto;
    border: 1px solid #ccc;
    border-radius: 1em;
}
.sheet-modal > .modal-actions {
    display: flex;
    margin: 1em;
    justify-content: center;
    gap: 1em;
}
@media screen and (max-width: 480px) {
    .sheet-modal {
        position: relative;
        top: 0;
        width: 100%;
        margin-top: 6em;
        min-height: calc(100vh - 6em);
        transform: translateY(100vh);
        background-color: white;
    }
    .modal-active > .sheet-modal {
        transform: translateY(0vh);
    }
}
@media screen and (min-width: 481px) {
    .sheet-modal {
        position: relative;
        top: 50%;
        width: 600px;
        height: 400px;
        margin: -200px auto;
        background-color: white;
        transform: translateY(100vh);
    }
    .modal-active > .sheet-modal {
        transform: translateY(0);
    }
}