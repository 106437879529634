.uploader-wrapper{
    max-width: 30em;
    padding: 1em;
    margin: auto;
    background-color: #eee;
}
.uploader-label.ant-btn {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin: 0;
}
.uploader-label > svg {
    margin-right: 0.5em;
}
.uploader-label + input {
    visibility: collapse;
    margin: 0;
    width: 0;
    height: 0;
    padding: 0;
}
.uploader-label + input + .image-preview {
    margin-top: 1em;
    width: 100%;
}
.uploader-label + input + .image-preview > .middle > svg {
    color: white;
    width: 3em;
    height: 3em;
    background-color: #818181b8;
    border-radius: 10px;
    padding: 10px;
}
.uploader-label + input + .image-preview > .middle > svg:hover, .uploader-label + input + .image-preview > .middle > svg:focus{
    background-color: red;
    color: #fff;
}
.uploader-label + input + .image-preview > .middle > div > svg {
    color: white;
    width: 3em;
    height: 3em;
    background-color: #818181b8;
    border-radius: 10px;
    padding: 10px;
}
.uploader-label + input + .image-preview > .middle > div > svg:hover, .uploader-label + input + .image-preview > .middle > div > svg:focus{
    background-color: rgb(34, 34, 34);
    color: #fff;
}

.uploader-label + input + .image-preview > img {
    width: 100%;
}

.black-bg{
    background: black;
    width: fit-content;
}

.image-preview:hover img {
    opacity: 0.5;
}

.image-preview > .middle {
    transition: .5 ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform:  translate(-50%, -50%);
    -ms-transform: translate(-50%,-50%);
    text-align: center;
    z-index: 1;
    cursor: pointer;
    margin: 10px;
    justify-content: space-between;
}

.image-preview:hover .middle {
    opacity: 1;
}
