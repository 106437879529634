.injury-table table,
.injury-table th,
.injury-table td {
  border: 1px solid #ccc;
  text-align: center !important;
  vertical-align: middle;
}
.injury-table {
  overflow: auto;
}
.injury-table table {
  margin-top: 7em;
  border-top: none;
  border-collapse: collapse;
}
.injury-table table > tbody > tr > th{
  position: absolute;
  z-index: 1;
  height: 3.6em;
  background-color: #1b4b63;
  color: white;
  border-color: transparent;
}
.head-section > th {
  z-index: 1;
}
.head-section > td.force-untilt > p{
  position: relative;
  top: unset;
  transform: none;
  text-align: center;
  padding: 0;
  border: none;
}
.injury-table table > tbody > tr > td:nth-child(even){
  background-color: #eee;
}
.injury-table td {
  position: relative;
}
.injury-table table > tbody > tr > td.table-checkbox{
  height: 3.6em;
  min-width: 3.6em;
}
.injury-table table > tbody > tr > td.table-checkbox:focus {
  outline: none;
  box-shadow: 0px 0px 0px 3px #45C1B8 inset;
}
.injury-table table > tbody > tr > td.table-checkbox[data-checked="true"]{
  background-color: #45C1B8;
}
.injury-table table > tbody > tr > td.table-checkbox[data-checked="true"]:focus {
  box-shadow: 0px 0px 0px 3px #1b4b63 inset;
}
.injury-table table > tbody > tr > td.table-checkbox::after{
  content: "";
  position: absolute;
  bottom: 0;
  right: calc(50% - 1.8em);
  width: 1.3em;
  height: 2.5em;
  border: 0.5em solid #fff0;
  border-left: none;
  border-top: none;
  transform-origin: bottom right;
  transform: translate(-2.2em, -0.8em) rotate(45deg);
}
.injury-table table > tbody > tr > td[data-checked="true"]::after{
  border-color: #ffff;
  animation: checked 0.2s linear forwards;
}
@keyframes checked {
  0% {
    width: 0%;
    height: 0%;
    transform: translate(-2.8em, -1.3em) rotate(45deg);
  }
  40% {
    width: 1.3em;
    height: 0em;
    transform: translate(-2.2em, -0.8em) rotate(45deg);
  }
  100% {
    width: 1.3em;
    height: 2.5em;
    transform: translate(-2.2em, -0.8em) rotate(45deg);
  }
}
.inj-table-add-txt-box{
  min-width: 20em;
}
.input-box {
  width: 250px;
}
.head-section tr,
.head-section td {
  border: none;
}

.head-section p {
  position: absolute;
  top: 2.6em;
  left: 0;
  margin-left: 0.5em;
  padding-left: 1em;
  text-align: left;
  transform-origin: top left;
  transform: rotate(-50deg);
  border-bottom: 1px solid #ccc;
}
@media only screen and (max-width: 1024px) {
  .input-box {
    width: 100%;
  }
}
