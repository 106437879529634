#transfer-to-definite-care > .column-view {
    margin-top: 1em;
    columns: 2 410px;
    column-gap: 1em;
}
.column-view > .section {
    padding: 0;
    display: block;
    break-inside: avoid-column;
}
.input-group{
    margin: 1em 0em;
}
.toggle-token-text > .input-group {
    margin: 0;
}
.toggle-token-text > .toggle-token-wrapper {
    margin: 1em 0;
}
input.assessor {
    margin-bottom: 1em;
}
.button-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1em;
}
.button-group > input {
    min-width: 20em;
}
.color{
    background-color: #3a3a3a;
}