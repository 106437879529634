.ems-map-controls {
    position: absolute;
    z-index: 1;
    margin: 10px;
    top: 3em;
    background: white;
    padding: 1em;
    border-radius: 3px;
    box-shadow: 0px 2px 2px #00000022;
}
.ems-map-controls label {
    padding-right: 1em;
}
