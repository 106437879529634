@import url(https://cdnjs.cloudflare.com/ajax/libs/bulma/0.7.2/css/bulma.min.css);

  .sticky-header{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: calc(100vh - 48px);
  }
  .scrollable-menu {
    position: relative;
    overflow: auto;
    height: calc(100vh - 207px);
    top: 0;
  }
  .ant-layout-sider-collapsed .scrollable-menu {
    height: calc(100vh - 148px);
  }
  .livenow {
    width: 30px;
    padding: 10px 40px 16px 40px;
    margin: 0 auto;
    display: inline-block;
  }
  .livenow > div {
    vertical-align: middle;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    position: absolute;
    margin: 0 auto;
    border:3px solid rgba(57,167,95,1);
    -webkit-animation: live 1.4s infinite ease-in-out;
    animation: live 1.4s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .child1 {
    background-color:rgba(57,167,95,0.3) !important;
    background-color:rgba(57,167,95,1) !important;
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s !important;
  }
  .child2 {
    -webkit-animation-delay: 0.16s;
    animation-delay: 0.16s !important;
  }
  .child3 {
    -webkit-animation-delay: 0.42s;
    animation-delay: 0.42s !important;
    border:3px solid rgba(57,167,95,0.5) !important;
  }
  .child4 {
    border:3px solid rgba(57,167,95,1) !important;
    -webkit-animation-delay: -0.42s;
    animation-delay: -0.42s !important;
  }
  
  @-webkit-keyframes live {
    0%, 80%, 100% { -webkit-transform: scale(0.6) }
    40% { -webkit-transform: scale(1.0) }
  }
  @keyframes live {
    0%, 80%, 100% { 
      transform: scale(0.6);
      -webkit-transform: scale(0.6);
    } 40% { 
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
  }


@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    height: 100%;
    width:100%;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: 8.5cm 7cm;
}


  .tableStyle table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }
      
    .tableStyle  td,.tableStyle  th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }
    
    .tableStyle tr:nth-child(even) {
        background-color: #dddddd;
    }


._3HlAJj440puOYEVd-uRPSg {
  display: flex;
  align-items: flex-start;
  font-family: helvetica, arial, sans-serif;
  font-size: 12px;
  line-height: 1.2;
  max-width: 100%;
}

.sm4MgZC6nd4bGHyszZmS_ {
  width: 220px;
  padding: 20px;
}

.CnDtqLY4Un7ghc_ePnT31 {
  flex-grow: 1;
}

._3zEYgTD8XrD7d7Tz0yoGRz {
  max-height: 200px;
  overflow: auto;
  font-size: 10px;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 6px 8px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 6px 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 6px 8px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 6px 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

:root {
    --accent-color: #45C1B8;
    --indicator-color: #b7e8e5;
}
.neo-picker-cancel {
    position: fixed;
    inset: 0;
    background-color: #0001;
    opacity: 0;
    pointer-events: none;
}
.neo-picker {
    position: relative;
    top: 0;
    height: 2em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    box-sizing: content-box;
    display: flex;
    z-index: 1;
}
.neo-picker.bare {
    display: block;
    height: unset;
}
.neo-picker.bare .neo-picker-item-wrapper{
    height: 2em;
}
.neo-picker.bare .neo-picker-list-container{
    max-height: 8em;
}
.neo-picker.bare .neo-picker-item-position{
    display: flex;
    flex-direction: column-reverse;
}
button.neo-bare-stepper-up, button.neo-bare-stepper-back {
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    height: 2em;
    border: 1px solid #45C1B8;
    border: 1px solid var(--accent-color);
    color: #45C1B8;
    color: var(--accent-color);
    background-color: white;
}
button.neo-bare-stepper-up:hover, button.neo-bare-stepper-back:hover {
    background-color: #45C1B8;
    background-color: var(--accent-color);
    color: white;
}
button.neo-bare-stepper-up {
    border-radius: 0.3em 0.3em 0 0;
}
button.neo-bare-stepper-back {
    border-radius: 0 0 0.3em 0.3em;
}
.neo-picker-list-container::-webkit-scrollbar{
    display: none;
    width: 0;
}
.neo-picker-list-container{
    overflow: auto;
}
.neo-picker.right .neo-picker-item{
    justify-content: right;
}
.neo-picker.bare .neo-picker-item-wrapper{
    border-radius: 0;
}
.neo-picker .neo-picker-item-wrapper {
    position: relative;
    top: 0;
    overflow: hidden;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #6662;
    border-bottom: 1px solid #6664;
    border-radius: 0.2em 0 0 0.2em;
    background-color: #fff;
    transition: 0.2s;
    z-index: 1;
}
.neo-picker[data-openned="true"] .neo-picker-item-wrapper > .neo-picker-item-position > .neo-picker-item:hover {
    background-color: #45C1B8;
    background-color: var(--accent-color);
    color: white;
}
.neo-picker[data-openned="true"] .neo-picker-item-wrapper > .neo-picker-item-position > .neo-picker-item.picked{
    background-color: #b7e8e5;
    background-color: var(--indicator-color);
    color: black;
}
.neo-picker:not([data-openned="true"]) .neo-picker-item-wrapper > .neo-picker-item-position > .neo-picker-item:hover {
    background-color: #fff;
}
.neo-picker-item-position {
    position: relative;
    top: 0;
    transition: 0.2s;
}
.neo-picker-item {
    height: 2em;
    padding: 0 0.5em 0 1em;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}
:root{
    --accent-color: #45C1B8;
}
.neo-stepper {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height: 2em;
    z-index: 1;
}
.neo-stepper-controller {
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: center;
}
.neo-stepper-controller > button,
.neo-stepper-expand {
    border: 1px solid #45C1B8;
    border: 1px solid var(--accent-color);
    color: #45C1B8;
    color: var(--accent-color);
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2em;
    height: 2em;
}
.neo-stepper-controller > button:hover{
    background-color: #45C1B8;
    background-color: var(--accent-color);
    color: white;
}
.neo-stepper-expand {
    border-radius: 0 0.2em 0.2em 0;
}
.neo-stepper-expand > svg {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}
button.neo-stepper-up {
    border-radius: 0.3em 0.3em 0 0;
}
button.neo-stepper-back {
    border-top: none;
    border-radius: 0 0 0.3em 0.3em;
}
.neo-stepper > input {
    border: 1px solid #6662;
    border-bottom-color: #6664;
    border-radius: 0.2em 0 0 0.2em;
    height: 2em;
    padding: 0 0.5em;
}
.neo-stepper-expand:focus, .neo-stepper > input:focus, .neo-stepper-controller > button:focus {
    outline: none;
    border-color: #45C1B8;
    border-color: var(--accent-color);
    box-shadow: 0px 0px 0px 2px #45C1B8 inset;
    box-shadow: 0px 0px 0px 2px var(--accent-color) inset;
}
:root{
    --accent-color: #45C1B8;
}
.neo-scratcher {
    position: relative;
    height: 10em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    overflow: auto;
    background-color: #45C1B8;
    background-color: var(--accent-color);
    border: 1px solid #45C1B8;
    border: 1px solid var(--accent-color);
    border-radius: 0.3em;
}
.neo-scratcher > input {
    position: relative;
    text-align: right;
    border: 1px solid #6662;
    border-bottom: 1px solid #6664;
    border-radius: 0.2em;
}
.neo-scratcher > input.input-error {
    border-color: red;
    color: red;
}
.neo-scratching-area{
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(transparent 90%, #8884 90%);
    background-size: 100% 10px;
    background-repeat: repeat-y;
    -webkit-user-select: none;
            user-select: none;
    touch-action: none;
    cursor: default;
}
.neo-horizontal-scratcher > .neo-scratching-area{
    background-image: linear-gradient(90deg, transparent 90%, #8884 90%);
    background-size: 10px 100%;
    background-repeat: repeat-x;
}
.neo-scratcher.neo-horizontal-scratcher {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 2em;
}
.neo-input-error-wrapper {
    position: absolute;
    left: calc(100% + 0.2em);
    top: 0;
    margin-top: -0.6em;
    -webkit-filter: drop-shadow(0px 3px 6px #6664);
            filter: drop-shadow(0px 3px 6px #6664);
    z-index: 1;
}
.neo-input-error {
    background-color: white;
    white-space: nowrap;
    padding: 1em;
    -webkit-clip-path: polygon(
        0% 2em,
        1em 0%,
        100% 0%,
        100% 100%,
        1em 100%
    );
            clip-path: polygon(
        0% 2em,
        1em 0%,
        100% 0%,
        100% 100%,
        1em 100%
    );
    font-size: small;
}
.neo-input-error > svg {
    color: red;
    width: 1.6em;
    height: 1.6em;
    padding: 0.2em;
    border: 1px solid red;
    border-radius: 1em;
    margin: 0 0.4em;
}
.neo-input-error > svg:hover {
    background-color: red;
    color: white;
}
:root {
    --accent-color: #45C1B8;
}
.neo-cal-placeholder {
    position: relative;
    width: 0;
    height: 0;

}
.neo-date-picker {
    position: absolute;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1em;
    background-color: white;
    display: flex;
    grid-gap: 1em;
    gap: 1em;
    box-shadow: 0px 3px 6px #6664;
    z-index: 1;
}
.neo-date-picker-input.abbr {
    width: 11em;
}
.neo-date-picker-input{
    width: 14em;
    border: 1px solid #6662;
    border-bottom-color: #6664;
    border-radius: 0.2em;
    padding: 0.3em 0.5em;
}
.neo-date-picker-input:focus {
    outline: none;
    box-shadow: 0px 0px 0px 3px #45C1B8 inset;
    box-shadow: 0px 0px 0px 3px var(--accent-color) inset;
}
.neo-date-picker-input-wrapper {
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.neo-date-picker-input-wrapper svg {
    position: absolute;
    right: 0;
    width: 2em;
    height: 100%;
    padding: 0.5em;
    color: #6668;
}
.neo-clock {
    display: flex;
    flex-wrap: nowrap;
    grid-area: time;
    grid-gap: 0.3em;
    gap: 0.3em;
}
.neo-clock > .neo-scratcher, .neo-clock > .neo-time-wrapper {
    height: 100%;
    overflow-y: auto;
    scrollbar-gutter: stable;
}
.neo-time{
    display: grid;
    grid-template-rows: [start] 75% [end];
    grid-template-areas:
        "time"
        "button";
}
.neo-date-picker-buttons {
    grid-area: button;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 0.5em;
    gap: 0.5em;
}
.neo-time .neo-set-now, .neo-time .neo-done-date-picking {
    width: 100%;
    border: none;
    border-radius: 0.2em;
    background-color: #45C1B8;
    background-color: var(--accent-color);
    color: white;
}
.neo-time > .neo-set-now:focus{
    outline: none;
}
.neo-calendar {
    position: relative;
}
.neo-calendar > .neo-calendar-controller {
    position: relative;
    top: 0;
    display: flex;
    grid-gap: 1em;
    gap: 1em;
    align-items: stretch;
    justify-content: space-between;
}
.neo-calendar th, .neo-calendar td {
    width: 3em;
    text-align: right;
    padding: 0.5em;
    -webkit-user-select: none;
            user-select: none;
}
.neo-calendar td.today, .neo-calendar td.picked {
    border-radius: 0.3em;
}
.neo-calendar td.today {
    border-radius: 0.3em;
    border: 1px solid #45C1B8;
    border: 1px solid var(--accent-color)
}
.neo-calendar td.picked {
    color: white;
    background-color: #45C1B8;
    background-color: var(--accent-color);
}
.neo-calendar th {
    cursor: default;
}
.neo-calendar td {
    cursor: pointer;
}
.neo-calendar th:first-child, .neo-calendar td:first-child{
    color: red;
}
.neo-calendar td.other-month:first-child{
    color: #f006;
}
.neo-calendar td.other-month {
    color: #6666;
}
.timelog-table > table,
.timelog-table > table > thead > tr > th,
.timelog-table > table > tbody > tr > th,
.timelog-table > table > tbody > tr > td {
  border: 1px solid #ccc;
  text-align: center !important;
  padding: 5px;
  white-space: nowrap;
  vertical-align: middle;
}
.timelog-table > table > tbody > tr:first-child > td {
  padding: 0;
}
.timelog-table > table > tbody > tr:first-child > td .neo-date-picker-input-wrapper {
  margin: auto;
}
.timelog-table > table > tbody > tr:first-child > td .neo-date-picker-input-wrapper > .neo-date-picker-input {
  border: none;
}
.input-box {
  width: 250px;
}
.timelog-table {
  overflow: auto;
  min-height: 350px;
}
.timelog-table > table {
  border-collapse: collapse;
}
.timelog-table > table > tbody > tr > th {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: #1b4b63;
  color: white;
  border-color: #1b4b63;
  z-index: 1;
}
.timelog-table > table > tbody > tr:nth-child(even) > th {
  background-color: #143e52;
  border-color: #143e52;
}
.timelog-table > table > tbody > tr > td > .ant-picker.ant-picker-small {
  width: 11em;
}
@media only screen and (max-width: 1024px) {
  .input-box {
    width: 100%;
  }
}

:root {
    --accent-color: #45C1B8;
    --primary-color: #1b4b63;
    --primary-color-text: #eee;
}
.toggle-token-wrapper.left > .toggle-token, 
.toggle-token-wrapper.start > .toggle-token {
    justify-content: flex-start;
}
.toggle-token-wrapper.right > .toggle-token, 
.toggle-token-wrapper.end > .toggle-token {
    justify-content: flex-end;
}
.toggle-token-wrapper.right > .toggle-token-title, 
.toggle-token-wrapper.end > .toggle-token-title {
    flex-direction: row-reverse;
    padding-left: 1em;
    padding-right: 1em;
}
.toggle-token-wrapper.center > .toggle-token, 
.toggle-token-wrapper.middle > .toggle-token {
    justify-content: center;
}
.toggle-token-panel {
    background: #fff;
    border: 2px solid #1b4b63;
    border: 2px solid var(--primary-color);
    border-radius: 1em;
    margin-bottom: 1em;
    overflow: hidden;
}
.toggle-token-panel > .toggle-token{
    margin: 1em;
}
.toggle-token {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1em;
    gap: 1em;
}
.toggle-token-text, .toggle-token-title{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: baseline;
    padding: 0 1em;
}
.toggle-token-title{
    background-color: #1b4b63;
    background-color: var(--primary-color);
    padding: 0.5em 1em;
    margin-bottom: 1em;
}

.toggle-token-title > h2, .toggle-token-text > h2 {
    margin: 0;
    padding: 0.2em 0;
    color: #eee;
    color: var(--primary-color-text);
}
.toggle-token > div, .toggle-token.force-break > div, .toggle-token-title > [data-value="no_data"] {
    position: relative;
    font-weight: 700;
    white-space: nowrap;
    padding: 0.2em 1em;
    border: 3px solid transparent;
    border-radius: 1.5em;
    transition: 0.2s ease-in-out;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    display: -webkit-box;
    overflow: hidden;
}
.toggle-token > div > div {
    position: relative;
    padding: 0.3em 0;
}
.toggle-token > .toggle-token-choice > .toggle-token-label > svg {
    -webkit-transform: translate(0.3em, -0.1em);
            transform: translate(0.3em, -0.1em);
}
.toggle-token > [data-included="true"], .toggle-token-title > [data-none="true"] {
    background: #45C1B8;
    background: var(--accent-color);
    color: #fff;
}
.toggle-token > :not([data-included="true"]), .toggle-token-title > [data-none=false]{
    background: #eee;
    color: #222;
}
.toggle-token[data-max="1"]:not(.force-break) {
    flex-wrap: nowrap;
    grid-gap: 0;
    gap: 0;
}
.toggle-token[data-max="1"]:not(.force-break) > div {
    margin-right: 0;
    margin-bottom: 0;
    border-radius: 0;
}
.toggle-token[data-max="1"]:not(.force-break) > div:first-child {
    border-radius: 1.5em 0 0 1.5em;
}
.toggle-token[data-max="1"]:not(.force-break) > div:last-child {
    border-radius: 0 1.5em 1.5em 0;
}
.toggle-token[data-max="1"]:not(.force-break) > div + div {
    border-left: 1px solid #8886;
}
.toggle-token > div > .extra-input, .toggle-token > div > .attachment {
    position: relative;
    left: 0.8em;
    padding: 0;
    display: block;
    transition: 0.2s ease-out;
    opacity: 1;
}
.toggle-token > div > .extra-input {
    width: 11em;
}
.toggle-token > div > .attachment {
    width: 8em;
}
.toggle-token > div:not([data-included="true"]) > .extra-input, .toggle-token > div:not([data-included="true"]) > .attachment {
    width: 0;
    margin-left: 0;
    opacity: 0;
    pointer-events: none;
}
.toggle-token > div > .extra-input > input{
    width: 11em;
}
.toggle-token > div > .attachment > div {
    height: 100%;
    margin-left: 0.5em;
    display: -webkit-box;
}
.toggle-token > div > .attachment > div > input {
    width: 4em;
    margin-left: 0.5em;
}
.toggle-token > div > .attachment > div > .label {
    color: white;
    font-size: 1em;
    margin: 0.3em 0;
}
.toggle-token > div > .extra-input > input, .toggle-token > div > .attachment > div > input {
    position: absolute;
    right: 0;
    height: 100%;
    padding: 0.2em 1em;
    border: none;
    border-radius: 1em;
    color: #000;
    transition: 0.2s ease-out;
}
.extra-input > input:focus {
    box-shadow: 0px 3px 6px #0003;
}
input.time-input {
    width: 7em;
    text-align: right;
}
.time-table > table th, .time-table > table td {
  text-align: center !important;
  padding: 5px;
}
.input-box {
  width: 250px;
}
.time-table {
  flex: 1 0 100%;
  display: flex;
  justify-content: flex-start;
  overflow: auto;
}
table {
  border-collapse: separate;
}
.table-halo > th:first-child {
  border-radius: 1em 0em 0em 0em;
}
.table-halo > th:last-child {
  border-radius: 0em 1em 0em 0em;
}
.table-record:last-of-type > td:first-child {
  border-radius: 0em 0em 0em 1em;
}
.table-record:last-of-type > td:last-child {
  border-radius: 0em 0em 1em 0em;
}
.table-halo > td{
  position: relative;
  padding: 0.5em 1em;
}
.table-halo > td::before {
  content: attr(data-group);
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 2em);
  height: calc(100% - 0.4em);
  background-color: white;
  color: #1b4b63;
  z-index: 0;
  border-radius: 1em;
  margin: 0.2em 1em;
  padding: 0.2em;
  font-weight: 700;
}
.table-halo > th, .table-halo > td, .table-header > th{
  background-color: #1b4b63;
  color: white;
}
/* th:nth-child(odd) {
  border-radius: 6px 6px 0 0;
} */
tbody > tr > td > #excel{
  position: absolute;
  border: 3px solid #45C1B8;
  border-radius: 3px;
  transition: 0.1s ease-out;
  box-sizing: content-box;
  pointer-events: none;
}
.table-record {
  position: relative;
}
.table-record > td {
  position: relative;
  background-color: white;
}
.table-record > td:nth-child(odd) {
  background-color: #eee;
}
.table-record > td:nth-child(even) {
  background-color: #fafafa;
}
.table-record > td + td {
  border-left: none;
}
.table-record + .table-record > td {
  border-top: 1px solid #ccc8;
}
.table-record.picked + .table-record > td {
  border-top: none;
}
.table-record > td > svg {
  position: relative;
  left: 0;
  margin: 0.6em 0;
  z-index: 1;
  transition: color 0.2s ease-in-out;
}
.table-record.picked > td {
  border-color: transparent;
  color: #1b4b63;
}
.table-record.picked > td:nth-child(odd){
  background-color: #45C1B844;
} 
.table-record.picked > td:nth-child(even){
  background-color: #45C1B888;
} 
.table-record > td > svg + svg {
  margin-left: 0.6em;
}
.table-record > td > .remove:hover {
  color: red;
}
.table-record > td > .picker:hover {
  color: #45C1B8;
}
.table-record.picked > td > input::-webkit-input-placeholder {
  color:#45C1B8;
}
.table-record.picked > td > input::placeholder {
  color:#45C1B8;
}
.table-record > td > .ant-select {
  float: right;
}
.table-record > td > .ant-select > .ant-select-selector {
  border: none;
}
.table-record > td > .ant-select.ant-select-focused {
  outline: none;
}
.table-record > td > .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
}
.table-record > td > .ant-select-single.ant-select-open .ant-select-selection-item {
  color: #45C1B8;
}
tr.end-cap > td {
  background-color: #1b4b63;
  height: 1em;
  border-radius: 0 0 1em 1em;
}
.ant-popconfirm .ant-btn.ant-btn-primary{
  background-color: #ff5050;
  border-color: #ff5050;
}
.ant-popconfirm .ant-btn.ant-btn-primary:hover{
  background-color: #ff2424;
  border-color: #ff2424;
}
/* th, td {
  border: 1px solid #8884;
  border-collapse: collapse;
} */
/* .table-header > th:nth-child(odd), .table-record > td:nth-child(even) {
  background: #8884;
}
.table-record:nth-child(odd) {
  background-color: #eeea;
} */
.time-table > table > tbody > tr.table-record > td {
  position: relative;
  padding: 0;
}
.time-table > table > tbody > tr.table-record > td > input:focus {
  outline: none;
  box-shadow: 0px 0px 0px 3px #45C1B8 inset;
  border: #45C1B8;
  z-index: 1;
}
.time-table > table > tbody > tr.table-record > td > .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: 0px 0px 0px 3px #45C1B8 inset;
}
.table-record > td > input {
  background-color: transparent;
  border: none;
  padding: 5px 11px;
  min-width: 5em;
}
.table-halo > .time{
  padding-right: 16px;
  text-align: end !important;
}
.time, .dtx{
  vertical-align: bottom;
}
/* tr:last-child > td:nth-child(even) {
  border-radius: 0 0 6px 6px;
} */
/* .table-header, .table-header > .table-category {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
} */
th {
  padding: 1em;
}
td > input {
  width: 100%;
}
#Vital::before, #Neuro::before, #Pupils::before {
  content: attr(id);
  position: absolute;
  top: 0;
  padding-left: 1em;
  margin-top: -2em;
  flex: 1 0 100%;
}
@media only screen and (max-width: 1024px) {
  .input-box {
    width: 100%;
  }
}
#assessment {
  display: flex;
  flex-wrap: wrap;
}
h1 {
  font-weight: 700;
  font-size: 2em;
}
h2 {
  font-weight: 700;
  font-size: 1.5em;
  margin: 1em 0;
}
.sub-section {
  padding-left: 2em;
}
.flex {
  display: flex;
}
.flex > .ant-slider {
  width: 100%;
}
.form-layout {
  position: relative;
  -webkit-columns: 3 320px;
          columns: 3 320px;
  grid-column-gap: 2em;
  -webkit-column-gap: 2em;
          column-gap: 2em;
  margin-top: 1em;
}
.form-layout > .section {
  position: relative;
  padding: 0;
  display: flow-root;
}
.form-layout > .section > h1 {
  margin: 0.5em 0;
}
.form-layout > div{
  display: block;
  -webkit-column-break-inside: avoid;
          break-inside: avoid-column;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 0;
}
.form-layout > .section > .form-panel {
  padding: 0 1em 1em;
}
.injury-table table,
.injury-table th,
.injury-table td {
  border: 1px solid #ccc;
  text-align: center !important;
  vertical-align: middle;
}
.injury-table {
  overflow: auto;
}
.injury-table table {
  margin-top: 7em;
  border-top: none;
  border-collapse: collapse;
}
.injury-table table > tbody > tr > th{
  position: absolute;
  z-index: 1;
  height: 3.6em;
  background-color: #1b4b63;
  color: white;
  border-color: transparent;
}
.head-section > th {
  z-index: 1;
}
.head-section > td.force-untilt > p{
  position: relative;
  top: unset;
  -webkit-transform: none;
          transform: none;
  text-align: center;
  padding: 0;
  border: none;
}
.injury-table table > tbody > tr > td:nth-child(even){
  background-color: #eee;
}
.injury-table td {
  position: relative;
}
.injury-table table > tbody > tr > td.table-checkbox{
  height: 3.6em;
  min-width: 3.6em;
}
.injury-table table > tbody > tr > td.table-checkbox:focus {
  outline: none;
  box-shadow: 0px 0px 0px 3px #45C1B8 inset;
}
.injury-table table > tbody > tr > td.table-checkbox[data-checked="true"]{
  background-color: #45C1B8;
}
.injury-table table > tbody > tr > td.table-checkbox[data-checked="true"]:focus {
  box-shadow: 0px 0px 0px 3px #1b4b63 inset;
}
.injury-table table > tbody > tr > td.table-checkbox::after{
  content: "";
  position: absolute;
  bottom: 0;
  right: calc(50% - 1.8em);
  width: 1.3em;
  height: 2.5em;
  border: 0.5em solid #fff0;
  border-left: none;
  border-top: none;
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  -webkit-transform: translate(-2.2em, -0.8em) rotate(45deg);
          transform: translate(-2.2em, -0.8em) rotate(45deg);
}
.injury-table table > tbody > tr > td[data-checked="true"]::after{
  border-color: #ffff;
  -webkit-animation: checked 0.2s linear forwards;
          animation: checked 0.2s linear forwards;
}
@-webkit-keyframes checked {
  0% {
    width: 0%;
    height: 0%;
    -webkit-transform: translate(-2.8em, -1.3em) rotate(45deg);
            transform: translate(-2.8em, -1.3em) rotate(45deg);
  }
  40% {
    width: 1.3em;
    height: 0em;
    -webkit-transform: translate(-2.2em, -0.8em) rotate(45deg);
            transform: translate(-2.2em, -0.8em) rotate(45deg);
  }
  100% {
    width: 1.3em;
    height: 2.5em;
    -webkit-transform: translate(-2.2em, -0.8em) rotate(45deg);
            transform: translate(-2.2em, -0.8em) rotate(45deg);
  }
}
@keyframes checked {
  0% {
    width: 0%;
    height: 0%;
    -webkit-transform: translate(-2.8em, -1.3em) rotate(45deg);
            transform: translate(-2.8em, -1.3em) rotate(45deg);
  }
  40% {
    width: 1.3em;
    height: 0em;
    -webkit-transform: translate(-2.2em, -0.8em) rotate(45deg);
            transform: translate(-2.2em, -0.8em) rotate(45deg);
  }
  100% {
    width: 1.3em;
    height: 2.5em;
    -webkit-transform: translate(-2.2em, -0.8em) rotate(45deg);
            transform: translate(-2.2em, -0.8em) rotate(45deg);
  }
}
.inj-table-add-txt-box{
  min-width: 20em;
}
.input-box {
  width: 250px;
}
.head-section tr,
.head-section td {
  border: none;
}

.head-section p {
  position: absolute;
  top: 2.6em;
  left: 0;
  margin-left: 0.5em;
  padding-left: 1em;
  text-align: left;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-transform: rotate(-50deg);
          transform: rotate(-50deg);
  border-bottom: 1px solid #ccc;
}
@media only screen and (max-width: 1024px) {
  .input-box {
    width: 100%;
  }
}

.uploader-wrapper{
    max-width: 30em;
    padding: 1em;
    margin: auto;
    background-color: #eee;
}
.uploader-label.ant-btn {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin: 0;
}
.uploader-label > svg {
    margin-right: 0.5em;
}
.uploader-label + input {
    visibility: collapse;
    margin: 0;
    width: 0;
    height: 0;
    padding: 0;
}
.uploader-label + input + .image-preview {
    margin-top: 1em;
    width: 100%;
}
.uploader-label + input + .image-preview > .middle > svg {
    color: white;
    width: 3em;
    height: 3em;
    background-color: #818181b8;
    border-radius: 10px;
    padding: 10px;
}
.uploader-label + input + .image-preview > .middle > svg:hover, .uploader-label + input + .image-preview > .middle > svg:focus{
    background-color: red;
    color: #fff;
}
.uploader-label + input + .image-preview > .middle > div > svg {
    color: white;
    width: 3em;
    height: 3em;
    background-color: #818181b8;
    border-radius: 10px;
    padding: 10px;
}
.uploader-label + input + .image-preview > .middle > div > svg:hover, .uploader-label + input + .image-preview > .middle > div > svg:focus{
    background-color: rgb(34, 34, 34);
    color: #fff;
}

.uploader-label + input + .image-preview > img {
    width: 100%;
}

.black-bg{
    background: black;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.image-preview:hover img {
    opacity: 0.5;
}

.image-preview > .middle {
    transition: .5 ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform:  translate(-50%, -50%);
            transform:  translate(-50%, -50%);
    -ms-transform: translate(-50%,-50%);
    text-align: center;
    z-index: 1;
    cursor: pointer;
    margin: 10px;
    justify-content: space-between;
}

.image-preview:hover .middle {
    opacity: 1;
}

.stroke-table table,
.stroke-table th,
.stroke-table td {
  border: 1px solid black;
  text-align: left;
  padding: 5px;
}
.treatment-table table,
.treatment-table th,
.treatment-table td {
  border: 1px solid black;
  text-align: left;
  padding: 8px;
}
.toggle-token > .toggle-token-choice[data-value="et_no"] > .extra-input, .toggle-token > .toggle-token-choice[data-value="et_no"] > .extra-input > input, .toggle-token > .toggle-token-choice[data-value="et_no"] > .attachment {
  width: 6em;
}
.toggle-token > .toggle-token-choice[data-value="et_no"]:not([data-included="true"]) > .extra-input, .toggle-token > .toggle-token-choice[data-value="et_no"]:not([data-included="true"]) > .attachment {
  width: 0;
}
.ems-treatment-layout {
  -webkit-columns: 3 320px;
          columns: 3 320px;
}
.ems-treatment-layout > .toggle-token-panel {
  -webkit-column-break-inside: avoid;
          break-inside: avoid-column;
}
.ems-treatment-layout > .toggle-token-panel > textarea.ant-input {
  font-size: 1.2em;
  margin: 1em;
  width: calc(100% - 2em);
}
.ems-treatment-layout > .toggle-token-panel > textarea.ant-input::-webkit-input-placeholder{
  font-style: italic;
  font-weight: 700;
}
.ems-treatment-layout > .toggle-token-panel > textarea.ant-input::placeholder{
  font-style: italic;
  font-weight: 700;
}
.additional-form-section {
  -webkit-columns: 2 20em;
          columns: 2 20em;
}
.additional-form-subsection {
  -webkit-column-break-inside: avoid;
          break-inside: avoid-column;
}
#transfer-to-definite-care > .column-view {
    margin-top: 1em;
    -webkit-columns: 2 410px;
            columns: 2 410px;
    grid-column-gap: 1em;
    -webkit-column-gap: 1em;
            column-gap: 1em;
}
.column-view > .section {
    padding: 0;
    display: block;
    -webkit-column-break-inside: avoid;
            break-inside: avoid-column;
}
.input-group{
    margin: 1em 0em;
}
.toggle-token-text > .input-group {
    margin: 0;
}
.toggle-token-text > .toggle-token-wrapper {
    margin: 1em 0;
}
input.assessor {
    margin-bottom: 1em;
}
.button-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
}
.button-group > input {
    min-width: 20em;
}
.color{
    background-color: #3a3a3a;
}
.partition{
    position: fixed;
    inset: 0;
    overflow: auto;
    pointer-events: none;
    z-index: 1;
}
.partition::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: #222a;
    opacity: 0;
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
    transition: 0.2s ease-out;
}
.partition.modal-active {
    pointer-events: all;
}
.partition.modal-active::before {
    opacity: 1;
}
.sheet-modal {
    padding: 1em;
    transition: 0.2s ease-in-out;
}
.sheet-modal > h2 {
    text-align: center;
}
.sheet-modal > .signature-area {
    position: relative;
    margin: auto;
    border: 1px solid #ccc;
    border-radius: 1em;
}
.sheet-modal > .modal-actions {
    display: flex;
    margin: 1em;
    justify-content: center;
    grid-gap: 1em;
    gap: 1em;
}
@media screen and (max-width: 480px) {
    .sheet-modal {
        position: relative;
        top: 0;
        width: 100%;
        margin-top: 6em;
        min-height: calc(100vh - 6em);
        -webkit-transform: translateY(100vh);
                transform: translateY(100vh);
        background-color: white;
    }
    .modal-active > .sheet-modal {
        -webkit-transform: translateY(0vh);
                transform: translateY(0vh);
    }
}
@media screen and (min-width: 481px) {
    .sheet-modal {
        position: relative;
        top: 50%;
        width: 600px;
        height: 400px;
        margin: -200px auto;
        background-color: white;
        -webkit-transform: translateY(100vh);
                transform: translateY(100vh);
    }
    .modal-active > .sheet-modal {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    background-color: #1b4b63;
    color: #fff;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 0.5em 0.5em 0 0;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    background-color: #fff;
}
.map-entry {
  position: relative;
  display: flex;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
}
.map-marker {
  position: relative;
}
.map-marker > svg {
  color: #ea4336;
  width: 2em;
  height: 100%;
}
.map-marker::after {
  content: attr(data-label);
  position: absolute;
  inset: 0;
  text-align: center;
  font-weight: 700;
}

.ems-map-controls {
    position: absolute;
    z-index: 1;
    margin: 10px;
    top: 3em;
    background: white;
    padding: 1em;
    border-radius: 3px;
    box-shadow: 0px 2px 2px #00000022;
}
.ems-map-controls label {
    padding-right: 1em;
}

.storeCardContainer {
  padding: 20px 20px 40px 20px;
}
.storeCard {
  background-color: #fff;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
}

.message {
  display: flex;
  align-items: center;
  background: #ffffff
}

.send {
  flex-direction: row-reverse;
}

.send p {
  align-self: flex-end;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

