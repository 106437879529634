.stroke-table table,
.stroke-table th,
.stroke-table td {
  border: 1px solid black;
  text-align: left;
  padding: 5px;
}
.treatment-table table,
.treatment-table th,
.treatment-table td {
  border: 1px solid black;
  text-align: left;
  padding: 8px;
}
.toggle-token > .toggle-token-choice[data-value="et_no"] > .extra-input, .toggle-token > .toggle-token-choice[data-value="et_no"] > .extra-input > input, .toggle-token > .toggle-token-choice[data-value="et_no"] > .attachment {
  width: 6em;
}
.toggle-token > .toggle-token-choice[data-value="et_no"]:not([data-included="true"]) > .extra-input, .toggle-token > .toggle-token-choice[data-value="et_no"]:not([data-included="true"]) > .attachment {
  width: 0;
}
.ems-treatment-layout {
  columns: 3 320px;
}
.ems-treatment-layout > .toggle-token-panel {
  break-inside: avoid-column;
}
.ems-treatment-layout > .toggle-token-panel > textarea.ant-input {
  font-size: 1.2em;
  margin: 1em;
  width: calc(100% - 2em);
}
.ems-treatment-layout > .toggle-token-panel > textarea.ant-input::placeholder{
  font-style: italic;
  font-weight: 700;
}
.additional-form-section {
  columns: 2 20em;
}
.additional-form-subsection {
  break-inside: avoid-column;
}