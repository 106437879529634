:root{
    --accent-color: #45C1B8;
}
.neo-stepper {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height: 2em;
    z-index: 1;
}
.neo-stepper-controller {
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: center;
}
.neo-stepper-controller > button,
.neo-stepper-expand {
    border: 1px solid var(--accent-color);
    color: var(--accent-color);
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2em;
    height: 2em;
}
.neo-stepper-controller > button:hover{
    background-color: var(--accent-color);
    color: white;
}
.neo-stepper-expand {
    border-radius: 0 0.2em 0.2em 0;
}
.neo-stepper-expand > svg {
    transform: rotate(90deg);
}
button.neo-stepper-up {
    border-radius: 0.3em 0.3em 0 0;
}
button.neo-stepper-back {
    border-top: none;
    border-radius: 0 0 0.3em 0.3em;
}
.neo-stepper > input {
    border: 1px solid #6662;
    border-bottom-color: #6664;
    border-radius: 0.2em 0 0 0.2em;
    height: 2em;
    padding: 0 0.5em;
}
.neo-stepper-expand:focus, .neo-stepper > input:focus, .neo-stepper-controller > button:focus {
    outline: none;
    border-color: var(--accent-color);
    box-shadow: 0px 0px 0px 2px var(--accent-color) inset;
}