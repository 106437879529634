.message {
  display: flex;
  align-items: center;
  background: #ffffff
}

.send {
  flex-direction: row-reverse;
}

.send p {
  align-self: flex-end;
}
