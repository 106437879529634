
  .tableStyle table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }
      
    .tableStyle  td,.tableStyle  th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }
    
    .tableStyle tr:nth-child(even) {
        background-color: #dddddd;
    }

