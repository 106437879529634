.timelog-table > table,
.timelog-table > table > thead > tr > th,
.timelog-table > table > tbody > tr > th,
.timelog-table > table > tbody > tr > td {
  border: 1px solid #ccc;
  text-align: center !important;
  padding: 5px;
  white-space: nowrap;
  vertical-align: middle;
}
.timelog-table > table > tbody > tr:first-child > td {
  padding: 0;
}
.timelog-table > table > tbody > tr:first-child > td .neo-date-picker-input-wrapper {
  margin: auto;
}
.timelog-table > table > tbody > tr:first-child > td .neo-date-picker-input-wrapper > .neo-date-picker-input {
  border: none;
}
.input-box {
  width: 250px;
}
.timelog-table {
  overflow: auto;
  min-height: 350px;
}
.timelog-table > table {
  border-collapse: collapse;
}
.timelog-table > table > tbody > tr > th {
  position: sticky;
  left: 0;
  background-color: #1b4b63;
  color: white;
  border-color: #1b4b63;
  z-index: 1;
}
.timelog-table > table > tbody > tr:nth-child(even) > th {
  background-color: #143e52;
  border-color: #143e52;
}
.timelog-table > table > tbody > tr > td > .ant-picker.ant-picker-small {
  width: 11em;
}
@media only screen and (max-width: 1024px) {
  .input-box {
    width: 100%;
  }
}
