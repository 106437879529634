.time-table > table th, .time-table > table td {
  text-align: center !important;
  padding: 5px;
}
.input-box {
  width: 250px;
}
.time-table {
  flex: 1 0 100%;
  display: flex;
  justify-content: flex-start;
  overflow: auto;
}
table {
  border-collapse: separate;
}
.table-halo > th:first-child {
  border-radius: 1em 0em 0em 0em;
}
.table-halo > th:last-child {
  border-radius: 0em 1em 0em 0em;
}
.table-record:last-of-type > td:first-child {
  border-radius: 0em 0em 0em 1em;
}
.table-record:last-of-type > td:last-child {
  border-radius: 0em 0em 1em 0em;
}
.table-halo > td{
  position: relative;
  padding: 0.5em 1em;
}
.table-halo > td::before {
  content: attr(data-group);
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 2em);
  height: calc(100% - 0.4em);
  background-color: white;
  color: #1b4b63;
  z-index: 0;
  border-radius: 1em;
  margin: 0.2em 1em;
  padding: 0.2em;
  font-weight: 700;
}
.table-halo > th, .table-halo > td, .table-header > th{
  background-color: #1b4b63;
  color: white;
}
/* th:nth-child(odd) {
  border-radius: 6px 6px 0 0;
} */
tbody > tr > td > #excel{
  position: absolute;
  border: 3px solid #45C1B8;
  border-radius: 3px;
  transition: 0.1s ease-out;
  box-sizing: content-box;
  pointer-events: none;
}
.table-record {
  position: relative;
}
.table-record > td {
  position: relative;
  background-color: white;
}
.table-record > td:nth-child(odd) {
  background-color: #eee;
}
.table-record > td:nth-child(even) {
  background-color: #fafafa;
}
.table-record > td + td {
  border-left: none;
}
.table-record + .table-record > td {
  border-top: 1px solid #ccc8;
}
.table-record.picked + .table-record > td {
  border-top: none;
}
.table-record > td > svg {
  position: relative;
  left: 0;
  margin: 0.6em 0;
  z-index: 1;
  transition: color 0.2s ease-in-out;
}
.table-record.picked > td {
  border-color: transparent;
  color: #1b4b63;
}
.table-record.picked > td:nth-child(odd){
  background-color: #45C1B844;
} 
.table-record.picked > td:nth-child(even){
  background-color: #45C1B888;
} 
.table-record > td > svg + svg {
  margin-left: 0.6em;
}
.table-record > td > .remove:hover {
  color: red;
}
.table-record > td > .picker:hover {
  color: #45C1B8;
}
.table-record.picked > td > input::placeholder {
  color:#45C1B8;
}
.table-record > td > .ant-select {
  float: right;
}
.table-record > td > .ant-select > .ant-select-selector {
  border: none;
}
.table-record > td > .ant-select.ant-select-focused {
  outline: none;
}
.table-record > td > .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
}
.table-record > td > .ant-select-single.ant-select-open .ant-select-selection-item {
  color: #45C1B8;
}
tr.end-cap > td {
  background-color: #1b4b63;
  height: 1em;
  border-radius: 0 0 1em 1em;
}
.ant-popconfirm .ant-btn.ant-btn-primary{
  background-color: #ff5050;
  border-color: #ff5050;
}
.ant-popconfirm .ant-btn.ant-btn-primary:hover{
  background-color: #ff2424;
  border-color: #ff2424;
}
/* th, td {
  border: 1px solid #8884;
  border-collapse: collapse;
} */
/* .table-header > th:nth-child(odd), .table-record > td:nth-child(even) {
  background: #8884;
}
.table-record:nth-child(odd) {
  background-color: #eeea;
} */
.time-table > table > tbody > tr.table-record > td {
  position: relative;
  padding: 0;
}
.time-table > table > tbody > tr.table-record > td > input:focus {
  outline: none;
  box-shadow: 0px 0px 0px 3px #45C1B8 inset;
  border: #45C1B8;
  z-index: 1;
}
.time-table > table > tbody > tr.table-record > td > .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: 0px 0px 0px 3px #45C1B8 inset;
}
.table-record > td > input {
  background-color: transparent;
  border: none;
  padding: 5px 11px;
  min-width: 5em;
}
.table-halo > .time{
  padding-right: 16px;
  text-align: end !important;
}
.time, .dtx{
  vertical-align: bottom;
}
/* tr:last-child > td:nth-child(even) {
  border-radius: 0 0 6px 6px;
} */
/* .table-header, .table-header > .table-category {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
} */
th {
  padding: 1em;
}
td > input {
  width: 100%;
}
#Vital::before, #Neuro::before, #Pupils::before {
  content: attr(id);
  position: absolute;
  top: 0;
  padding-left: 1em;
  margin-top: -2em;
  flex: 1 0 100%;
}
@media only screen and (max-width: 1024px) {
  .input-box {
    width: 100%;
  }
}
#assessment {
  display: flex;
  flex-wrap: wrap;
}
h1 {
  font-weight: 700;
  font-size: 2em;
}
h2 {
  font-weight: 700;
  font-size: 1.5em;
  margin: 1em 0;
}
.sub-section {
  padding-left: 2em;
}
.flex {
  display: flex;
}
.flex > .ant-slider {
  width: 100%;
}
.form-layout {
  position: relative;
  columns: 3 320px;
  column-gap: 2em;
  margin-top: 1em;
}
.form-layout > .section {
  position: relative;
  padding: 0;
  display: flow-root;
}
.form-layout > .section > h1 {
  margin: 0.5em 0;
}
.form-layout > div{
  display: block;
  break-inside: avoid-column;
  height: fit-content;
  padding-bottom: 0;
}
.form-layout > .section > .form-panel {
  padding: 0 1em 1em;
}