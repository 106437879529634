.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 6px 8px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 6px 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}
