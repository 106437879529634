:root {
    --accent-color: #45C1B8;
    --primary-color: #1b4b63;
    --primary-color-text: #eee;
}
.toggle-token-wrapper.left > .toggle-token, 
.toggle-token-wrapper.start > .toggle-token {
    justify-content: flex-start;
}
.toggle-token-wrapper.right > .toggle-token, 
.toggle-token-wrapper.end > .toggle-token {
    justify-content: flex-end;
}
.toggle-token-wrapper.right > .toggle-token-title, 
.toggle-token-wrapper.end > .toggle-token-title {
    flex-direction: row-reverse;
    padding-left: 1em;
    padding-right: 1em;
}
.toggle-token-wrapper.center > .toggle-token, 
.toggle-token-wrapper.middle > .toggle-token {
    justify-content: center;
}
.toggle-token-panel {
    background: #fff;
    border: 2px solid var(--primary-color);
    border-radius: 1em;
    margin-bottom: 1em;
    overflow: hidden;
}
.toggle-token-panel > .toggle-token{
    margin: 1em;
}
.toggle-token {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
}
.toggle-token-text, .toggle-token-title{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: baseline;
    padding: 0 1em;
}
.toggle-token-title{
    background-color: var(--primary-color);
    padding: 0.5em 1em;
    margin-bottom: 1em;
}

.toggle-token-title > h2, .toggle-token-text > h2 {
    margin: 0;
    padding: 0.2em 0;
    color: var(--primary-color-text);
}
.toggle-token > div, .toggle-token.force-break > div, .toggle-token-title > [data-value="no_data"] {
    position: relative;
    font-weight: 700;
    white-space: nowrap;
    padding: 0.2em 1em;
    border: 3px solid transparent;
    border-radius: 1.5em;
    transition: 0.2s ease-in-out;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    display: -webkit-box;
    overflow: hidden;
}
.toggle-token > div > div {
    position: relative;
    padding: 0.3em 0;
}
.toggle-token > .toggle-token-choice > .toggle-token-label > svg {
    transform: translate(0.3em, -0.1em);
}
.toggle-token > [data-included="true"], .toggle-token-title > [data-none="true"] {
    background: var(--accent-color);
    color: #fff;
}
.toggle-token > :not([data-included="true"]), .toggle-token-title > [data-none=false]{
    background: #eee;
    color: #222;
}
.toggle-token[data-max="1"]:not(.force-break) {
    flex-wrap: nowrap;
    gap: 0;
}
.toggle-token[data-max="1"]:not(.force-break) > div {
    margin-right: 0;
    margin-bottom: 0;
    border-radius: 0;
}
.toggle-token[data-max="1"]:not(.force-break) > div:first-child {
    border-radius: 1.5em 0 0 1.5em;
}
.toggle-token[data-max="1"]:not(.force-break) > div:last-child {
    border-radius: 0 1.5em 1.5em 0;
}
.toggle-token[data-max="1"]:not(.force-break) > div + div {
    border-left: 1px solid #8886;
}
.toggle-token > div > .extra-input, .toggle-token > div > .attachment {
    position: relative;
    left: 0.8em;
    padding: 0;
    display: block;
    transition: 0.2s ease-out;
    opacity: 1;
}
.toggle-token > div > .extra-input {
    width: 11em;
}
.toggle-token > div > .attachment {
    width: 8em;
}
.toggle-token > div:not([data-included="true"]) > .extra-input, .toggle-token > div:not([data-included="true"]) > .attachment {
    width: 0;
    margin-left: 0;
    opacity: 0;
    pointer-events: none;
}
.toggle-token > div > .extra-input > input{
    width: 11em;
}
.toggle-token > div > .attachment > div {
    height: 100%;
    margin-left: 0.5em;
    display: -webkit-box;
}
.toggle-token > div > .attachment > div > input {
    width: 4em;
    margin-left: 0.5em;
}
.toggle-token > div > .attachment > div > .label {
    color: white;
    font-size: 1em;
    margin: 0.3em 0;
}
.toggle-token > div > .extra-input > input, .toggle-token > div > .attachment > div > input {
    position: absolute;
    right: 0;
    height: 100%;
    padding: 0.2em 1em;
    border: none;
    border-radius: 1em;
    color: #000;
    transition: 0.2s ease-out;
}
.extra-input > input:focus {
    box-shadow: 0px 3px 6px #0003;
}