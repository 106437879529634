:root{
    --accent-color: #45C1B8;
}
.neo-scratcher {
    position: relative;
    height: 10em;
    width: fit-content;
    overflow: auto;
    background-color: var(--accent-color);
    border: 1px solid var(--accent-color);
    border-radius: 0.3em;
}
.neo-scratcher > input {
    position: relative;
    text-align: right;
    border: 1px solid #6662;
    border-bottom: 1px solid #6664;
    border-radius: 0.2em;
}
.neo-scratcher > input.input-error {
    border-color: red;
    color: red;
}
.neo-scratching-area{
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(transparent 90%, #8884 90%);
    background-size: 100% 10px;
    background-repeat: repeat-y;
    user-select: none;
    touch-action: none;
    cursor: default;
}
.neo-horizontal-scratcher > .neo-scratching-area{
    background-image: linear-gradient(90deg, transparent 90%, #8884 90%);
    background-size: 10px 100%;
    background-repeat: repeat-x;
}
.neo-scratcher.neo-horizontal-scratcher {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 2em;
}
.neo-input-error-wrapper {
    position: absolute;
    left: calc(100% + 0.2em);
    top: 0;
    margin-top: -0.6em;
    filter: drop-shadow(0px 3px 6px #6664);
    z-index: 1;
}
.neo-input-error {
    background-color: white;
    white-space: nowrap;
    padding: 1em;
    clip-path: polygon(
        0% 2em,
        1em 0%,
        100% 0%,
        100% 100%,
        1em 100%
    );
    font-size: small;
}
.neo-input-error > svg {
    color: red;
    width: 1.6em;
    height: 1.6em;
    padding: 0.2em;
    border: 1px solid red;
    border-radius: 1em;
    margin: 0 0.4em;
}
.neo-input-error > svg:hover {
    background-color: red;
    color: white;
}